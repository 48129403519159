import $ from "jquery";
// export var Event=new Vue();
// export const ajaxUrl = "http://local.jb.net/index.php";
export const ajaxUrl = "https://scrm.jcjbqj.com/index.php";////////
// export const ajaxUrl = "http://scrmapp.jctmj.cn/index.php";
// export const ajaxUrl = "http://jbs.scrm.cn/index.php";

// export const ajaxUrl = "https://jieb.jctmj.cn/index.php";
import {
    message
} from 'ant-design-vue';
// import Antd from 'ant-design-vue';

export function requestXml(url, xmlType, callback, xmlData) {
    xmlData = xmlData || {};
    $.ajax(ajaxUrl + url, {
        data: xmlData,
        type: xmlType, // HTTP请求类型
        success: (res) => {
            if (res.code == 1) {
                var data = res.data || res.apply || res.tempbase;
                if (data) {
                    callback(data);
                } else {
                    message.info(res.msg)
                        // alert(res.msg);		
                    callback("success");
                }
            } else if (res.code == -1) {
                window.location.href = ajaxUrl + "/scrm/login/login?url=" + location.href
            } else if (res.code == -2) {
                callback("no")
            } else {
                message.info(res.msg)
                callback("error");
            }
        },
        error: function() {
            //异常处理；
            // message.error(error)
            // alert("error");
        }
    });
}

export function getDateTime(time, type = 1, flag = 1) {
    if (time == null || time == 0) {
        return;
    }
    var date;
    if (flag == 0) {
        date = new Date(time);
    } else {
        date = new Date(parseInt(time) * 1000);
    }
    var year = date.getFullYear();
    var mon = formatNumber(date.getMonth() + 1);
    var day = formatNumber(date.getDate());
    var hours = formatNumber(date.getHours());
    var minu = formatNumber(date.getMinutes());
    var sec = formatNumber(date.getSeconds());
    if (type == 1) {
        return year + '-' + mon + '-' + day;
    } else if (type == 2) {
        return hours + ':' + minu;
    } else if (type == 3) {
        return year + '-' + mon + '-' + day + '  ' + hours + ':' + minu;
    } else if (type == 4) {
        return hours + ':' + minu + ':' + sec;
    } else {
        return year + '-' + mon + '-' + day + '  ' + hours + ':' + minu + ':' + sec;
    }
}

function formatNumber(n) {
    if (parseInt(n) < 10) {
        return "0" + parseInt(n);
    } else {
        return n;
    }
}
export function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
export function fileUpload(e, callback) {

    let files = e.target.files[0];
    let formData = new FormData(); //构造一个
    formData.append('file', files);

    $.ajax({
        url: ajaxUrl + "/scrm/index/UpFiles",
        dataType: 'json',
        type: 'POST',
        data: formData,
        processData: false, // 使数据不做处理
        contentType: false, // 不要设置Content-Type请求头
        success: (res) => {

            if (res.code == 1) {
                var data = res.data || res.apply;
                if (data) {
                    callback(data);
                } else {
                    // alert(res.msg);		
                    callback("success");
                }
            }
        },
        error: () => {

        }
    })
}
// export default requestXml
// module.exports = {
//     requestXml: requestXml,
//     getDateTime: getDateTime,
// }